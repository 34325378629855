// REACT IMPORTS
import { FormattedMessage } from 'react-intl'

// ABSOLUTE IMPORTS
import classNames from 'classnames'
import BadgeListItem from 'components/badges/BadgeListItem'
import useIsAUNZ from 'components/hooks/useIsAUNZ'
import { footerBadges } from 'components/footer/Footer'
import { format } from 'date-fns'
import HooplaLogo from 'images/Hoopla_white.png'
import { useAutopilot } from 'state/useAutoPilot'
import { useKinds } from 'state/useKinds'
import { useUser } from 'state/useUser'

const columnStyles = 'flex flex-col mt-4 items-center sm:items-start'
const columnHeaderStyles = 'font-bold mt-4 mb-2'
const pStyles = 'mb-2 break-words'

function Footer() {
  // HOOKS
  const isAUNZ = useIsAUNZ()

  // STATE
  const enabledKinds = useKinds((state) => state.enabledKinds)
  const showAutoPilot = useAutopilot((state) => state.showAutoPilot)
  const user = useUser((state) => state.user)

  return (
    <footer className="w-full bg-hoopla-blue px-8 pb-3 text-slate-400">
      <div className="mx-auto max-w-7xl animate-pulse">
        <div className="mb-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg:grid-cols-6 bg:flex-row">
          <div className={columnStyles}>
            <img alt="hoopla" className="w-32 bg:w-44" src={HooplaLogo} />
            <p className={classNames(pStyles, 'sm:pl-4 bg:pl-8')}>
              <FormattedMessage
                id="footer.for.patrons"
                defaultMessage="For Patrons"
              />
            </p>
            <p className={classNames(pStyles, 'sm:pl-4 bg:pl-8')}>
              <FormattedMessage
                id="footer.for.libraries"
                defaultMessage="For Libraries"
              />
            </p>
            <p className={classNames(pStyles, 'sm:pl-4 bg:pl-8')}>
              <FormattedMessage
                id="footer.for.vendors"
                defaultMessage="For Vendors"
              />
            </p>
            <p className={classNames(pStyles, 'mt-2 sm:pl-4 bg:pl-8')}>
              <FormattedMessage
                id="footer.contact"
                defaultMessage="Contact Us"
              />
            </p>
          </div>
          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.our.company"
                defaultMessage="Our Company"
              />
            </h3>
            <p className={pStyles}>
              <FormattedMessage
                id="footer.our.story"
                defaultMessage="Our Story"
              />
            </p>
            <p className={pStyles}>
              <FormattedMessage
                id="footer.for.library"
                defaultMessage="Get hoopla for your Library"
              />
            </p>
            <p className={pStyles}>
              <FormattedMessage
                id="footer.your.content"
                defaultMessage="Get your content on hoopla"
              />
            </p>
            <p className={pStyles}>
              <FormattedMessage
                id="footer.join.team"
                defaultMessage="Join our team"
              />
            </p>
          </div>

          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.our.content"
                defaultMessage="Our Content"
              />
            </h3>
            {enabledKinds.map((kind) => (
              <p className={pStyles} key={kind.id}>
                {kind.plural}
              </p>
            ))}
          </div>

          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.our.communities"
                defaultMessage="Our Communities"
              />
            </h3>
            {!isAUNZ && (
              <p className={pStyles}>
                <FormattedMessage
                  id="footer.content.book.club"
                  defaultMessage="hoopla book club"
                />
              </p>
            )}
            <p className={pStyles}>
              <FormattedMessage
                id="footer.patron.support"
                defaultMessage="Patron Support - FAQs"
              />
            </p>
          </div>

          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>Our Social</h3>
            <p className={pStyles}>Facebook</p>
            <p className={pStyles}>Twitter</p>
            <p className={pStyles}>Instagram</p>
            <p className={pStyles}>YouTube</p>
            <p className={pStyles}>TikTok</p>
            <p className={pStyles}>LinkedIn</p>
          </div>

          <div className={columnStyles}>
            <h4 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.hoopla.available"
                defaultMessage="hoopla is available on"
              />
            </h4>
            <div className="grid grid-cols-1 gap-2">
              {footerBadges.map((badge: Badge, index: number) => (
                <BadgeListItem
                  key={index}
                  badge={badge}
                  className="m-auto w-40 sm:m-0"
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-row space-x-0.5 text-sm">
          <span>&copy;{format(new Date(), 'yyyy')}. All rights reserved. </span>
          <p>
            <FormattedMessage
              id="footer.p.privacy"
              defaultMessage="Privacy Policy"
            />
          </p>
          <span> | </span>
          <p>
            <FormattedMessage id="footer.p.terms" defaultMessage="Terms" />
          </p>
        </div>
        {/* THIS IS WHERE AUTOPILOT pS GET GENERATED */}
        {!user && showAutoPilot && <div className="be-ix-p-block" />}
      </div>
    </footer>
  )
}

export default Footer
